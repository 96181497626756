

























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import medicalCase from '@/components/dashboard/report/medicalCase.vue'
import doctor from '@/components/dashboard/report/doctor.vue'
import dayAdd from '@/components/dashboard/report/line.vue'
import count from '@/components/dashboard/report/count.vue'

@Component({
  components: {
    medicalCase,
    doctor,
    dayAdd,
    count
  },
  props: {
    history: Object,
    caseTotal: Number,
    doctorTotal: Number,
    doctorStatus: Array,
    status: Array
  }
})
export default class DashboardContent extends Vue {
  // init data
  created: string = 'created'
  approved: string = 'approved'
  docIcon: string = 'yisheng'
  caseIcon: string = 'shouye1'
  medicalCase: string = 'medicalCase'
  doctor: string = 'doctor'
  account: any =  JSON.parse(localStorage.getItem("account"))
  projects: string = JSON.parse(localStorage.getItem('projects'))
}
