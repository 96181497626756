


































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import dashboardHeader from '@/components/dashboard/header.vue'
import dashboardAside from '@/components/dashboard/aside.vue'
import dashboardContent from '@/components/dataOverview/content.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    dashboardHeader,
    dashboardAside,
    dashboardContent
  },
  mixins: [globalMixins]
})
export default class TempIndex extends Vue<globalMixins> {
  @Getter('allProjs') allProjs: any[]
  @Action('profile') profile: Function
  @Getter('dataviewHistory') history: Object
  @Getter('dataviewStatus') status: any[]
  @Getter('dataviewTotal') caseTotal: any[]
  @Action('dataViewStatsCase') dataViewStatsCase: Function
  @Getter('dataviewDoctorStatus') doctorStatus: Object[]
  @Getter('dataviewDoctorTotal') doctorTotal: Object[]
  @Action('dataViewStatsUser') statsDoctor: Function
  @Action('clearDataViewData') clearDataViewData: Function
  project: any = JSON.parse(localStorage.getItem("projects"))
  topic: string = ''
  topicSelections: any[] = []
  created () {
    this.getProjs()
  }
  getProjs () {
    this.profile().then(() => {
      this.allProjs && this.allProjs.map(item => {
        this.topicSelections.push({
          label: item.name,
          value: item.id
        })
      })
    })
  }
  destroyed () {
    // 清除数据
    this.clearDataViewData()
  }
  topicChange (projId) {
    let params = { projectId: projId }
    this.dataViewStatsCase({ params })
    this.statsDoctor({ params })
  }
}
